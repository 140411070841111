import React, {useState} from "react";
import 'react-toastify/dist/ReactToastify.css';
import {message} from "antd";
import {useEffect} from "react";

const AddStructuredPrompt = ({PromptName, PromptRequired, handleFieldChange, handleStateChange, handleNameChange, handleRequireChange}) => {
    const initdata = {name: '', requirement: ''};
    const [data, setData] = useState(initdata);
    useEffect(() => {
        setData({name: PromptName, requirement: PromptRequired})
    }, [PromptName, PromptRequired])

    const [loading, setLoading] = useState(false)

    const fetchData=()=> {
        setLoading(true);
        fetch('/sappercommunity/sapperchian/sapperRequire2json', {
            method: 'POST', // 请求方法（GET、POST、PUT、DELETE等）
            headers: {
               'Content-Type': 'application/json'
            },
            body: JSON.stringify({ // 要发送的数据
                name: data.name,
                requirement: data.requirement,
                status: 1,
                data: [],
                middleData: {},
                apiKey: localStorage.getItem('apiKey'),
                apiOrg: localStorage.getItem('apiOrg')
            })
        })
        .then(response => {
            return response.json();}) // 解析响应数据为 JSON
        .then(data => {
            handleFieldChange(data.data);
            handleStateChange(1);
            message.success("Persona and Audience are generated successfully");
            message.success("ContextControl is being generated");
            // setLoading(false);
            fetch('/sappercommunity/sapperchian/sapperRequire2json', {
                method: 'POST', // 请求方法（GET、POST、PUT、DELETE等）
                headers: {
                   'Content-Type': 'application/json'
                },
                body: JSON.stringify({ // 要发送的数据
                    name: data.name,
                    requirement: data.requirement,
                    status: 2,
                    data: data.data,
                    middleData: data.middleData,
                    apiKey: localStorage.getItem('apiKey'),
                    apiOrg: localStorage.getItem('apiOrg')
                })
            })
            .then(response => {
                return response.json();}) // 解析响应数据为 JSON
            .then(data => {
                handleFieldChange(data.data);
                handleStateChange(1);
                message.success("ContextControl is generated successfully");
                message.success("Instruction is being generated");
                fetch('/sappercommunity/sapperchian/sapperRequire2json', {
                    method: 'POST', // 请求方法（GET、POST、PUT、DELETE等）
                    headers: {
                       'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ // 要发送的数据
                        name: data.name,
                        requirement: data.requirement,
                        status: 3,
                        data: data.data,
                        middleData: data.middleData,
                        apiKey: localStorage.getItem('apiKey'),
                        apiOrg: localStorage.getItem('apiOrg')
                    })
                })
                .then(response => {
                    return response.json();}) // 解析响应数据为 JSON
                .then(data => {
                    message.success("All successfully generated");
                    handleFieldChange(data.data);
                    handleStateChange(1);
                    setLoading(false);
                })
                .catch(error => {
                    // 处理错误
                    console.error(error);
                    setLoading(false);
                    message.error('Please check the network and click Generate Prompt button again');
                });
            })
            .catch(error => {
                // 处理错误
                console.error(error);
                setLoading(false);
                message.error('Please check the network and click Generate Prompt button again');
            });
        })
        .catch(error => {
            // 处理错误
            console.error(error);
            setLoading(false);
            message.error('Please check the network and click Generate Prompt button again');
        });
    }

    return (
        <div>
            <div className="container-fluid" style={{padding: '5px'}}>
                <br/>
                <div style={{fontSize: '18px'}}>Please name your prompt.</div>
                <input
                    value={data.name}
                    onChange={(e) => {setData({...data, name: e.target.value}); handleNameChange(e.target.value)}}
                    className="form-control"
                    style={{resize: 'none'}}
                    placeholder= "Example: NPC Creator"
                />
                <br/>
                <p style={{fontSize: '18px'}}>Please tell me the role you want to create and who it serves.</p>
                <textarea
                    value={data.requirement}
                    onChange={(e) => {setData({...data, requirement: e.target.value}); handleRequireChange(e.target.value)}}
                    className="form-control"
                    rows= {(data.requirement.split("\n").length ===1 && 2) || data.requirement.split("\n").length}
                    placeholder= "Example: Based on the user's input description, generate a non-player character (NPC) for a role-playing game (RPG) that conforms to specific rules and is suitable for children."
                    style={{resize: "none"}}
                />
                <br/>
                <hr/>
                <br/>
                <button type="button"  className="btn StepButton" onClick={fetchData}
                >Step1: Generate Prompt</button>{loading && (<span style={{color: 'green'}}><span style={{paddingLeft: '10px'}}>Generating</span>
                <span className="spinner-border spinner-border-sm">
                </span></span>)}
                <br/>
            </div>
        </div>
    );
}

export default AddStructuredPrompt;
