import React, { useEffect, useState, useRef, useCallback} from 'react';
import './Space.css'
import { Modal, Input, Tooltip, message} from 'antd';
import {Image, OverlayTrigger} from "react-bootstrap";


export class WebSocketController {
  constructor(setMessages) {
    this.socket = new WebSocket('wss://www.jxselab.com:8000/ws/sapperchain/GetLLMResponse');
    this.ongoingServerMessage = { content: '', element: null };
    this.setMessages = setMessages;

    this.initializeWebSocket();
  }

  initializeWebSocket() {
    this.socket.addEventListener('open', () => {
      console.log('WebSocket Connected');
    });

    this.socket.addEventListener('message', (event) => {
      const messagePart = event.data;
      if (messagePart === "__END_OF_RESPONSE__") {
        // Server message is complete, reset the ongoing server message
        this.ongoingServerMessage = { content: '', element: null };
      } else {
        // Server message is ongoing
        if (!this.ongoingServerMessage.element) {
          // If there's no ongoing element, create one
          this.ongoingServerMessage.element = { content: '', role: "assistant" };
          this.setMessages(prevMessages => [...prevMessages, this.ongoingServerMessage.element]);
        }
        // Update the content of the ongoing server message
        this.ongoingServerMessage.content += messagePart;
        this.ongoingServerMessage.element.content = this.ongoingServerMessage.content;

        // Trigger a state update to render the updated message
        this.setMessages(prevMessages => [...prevMessages]);
      }
    });

    this.socket.addEventListener('close', () => {
      console.log('WebSocket Disconnected');
    });

    this.socket.addEventListener('error', (event) => {
      console.error('WebSocket Error: ', event);
    });
  }

  sendMessage(message) {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(message);
    }
  }

  close() {
    this.socket.close();
  }
}

const ChatBox = ({visible, onClose, chatTarget, Author, Password}) => {
  const User = process.env.PUBLIC_URL + '/user.png';
  const SPLSapper = process.env.PUBLIC_URL + '/splsapper.jpg';
  const [SPLPrompt, setSPLPrompt] = useState('');
  const [SPLName, setSPLName] = useState('');
  const [SPLWelcome, setSPLWelcome] = useState('');
  const [SampleQuery, setSampleQuery] = useState([])
  const websocketController = useRef(null);
  const [messages, setMessages] = useState([]);
  useEffect(() => {
    websocketController.current = new WebSocketController(setMessages);

    return () => {
      websocketController.current.close();
    };
  }, []);

  const sendMessage = useCallback((system, mess, query) => {
    if (query.trim() === "") {
      // 用户输入的消息去除前导和尾随空格后为空
      message.info("Please input a query");
      return;
    }
    setState({...state, question: ""})
    const newprompt = [
        ...mess.filter(
            (v) =>
                ["user", "assistant"].includes(v.role) && v.content !== ""
        )
    ];
    const prompt = [{role: "system", content: system}, ...newprompt, {role: "user", content: query}]
    websocketController.current.sendMessage(JSON.stringify(prompt));
    setMessages(prevMessages => [
        ...prevMessages,
        { content: query, role: "user" }
      ]);
  }, []);

  useEffect(() => {
      fetch('/sappercommunity/sapperchain/getUGAData', {
            method: 'POST', // 请求方法（GET、POST、PUT、DELETE等）
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ // 要发送的数据
                AuthorId: chatTarget.AuthorId,
                Password: Password,
                WorkId: chatTarget.WorkId,
            })
        })
            .then(response => response.json()) // 解析响应数据为 JSON
            .then(data => {
                // 处理响应数据
                setSPLPrompt(data.spl_prompt);
                setSPLName(data.spl_data.SPLName)
                if(data.spl_data.WelcomeInfo)
                    setSPLWelcome(data.spl_data.WelcomeInfo)
                else
                    setSPLWelcome("You can ask me anything.")
                if(data.spl_data.SampleQuery)
                    setSampleQuery(data.spl_data.SampleQuery)
                else
                    setSampleQuery(["", "", ""])
            })
            .catch(error => {
      });
  }, [Author, Password, chatTarget]);

    const initState = {
        endpoint: "https://api.openai.com/v1/chat/completions",
        apiKey: localStorage.getItem('apiKey'),
        apiOrg: localStorage.getItem('apiOrg'),
        model: "gpt-3.5-turbo-16k",
        max_tokens: 4096,
        stop_sequences: '',
        top_p: 1,
        presence_penalty: 0,
        frequency_penalty: 0,
        overTime: 30000,
        historyMessageNum: undefined,
        historyMessage: [{role: "welcome", content: SPLWelcome}],
        prompts: [{role: "system", content: SPLPrompt}],
        question: "",
        loading: false,
        controller: null,
        runPrompt: true,
        isPromptVisible: false,
        KeyVisible: false
    };

    const [state, setState] = useState(initState);

    useEffect(() => {
      setState((prevState) => ({
        ...prevState,
        prompts: [{ role: "system", content: SPLPrompt }],
        historyMessage: [{ role: "welcome", content: SPLWelcome}]
      }));
      setMessages((prevState) => ([{ role: "welcome", content: SPLWelcome}]));
    }, [SPLPrompt, SPLWelcome]);

    const editMessage = (idx)=> {
        stopStreamFetch();
        const newQuestion = messages[idx].content;
        const newHistoryMessage = messages.slice(0, idx);
        setMessages(newHistoryMessage)
        setState({...state, question: newQuestion, historyMessage: newHistoryMessage});
    }

    const stopStreamFetch = () => {
        if (state.controller) {
            state.controller.abort("__ignore");
        }
    };

    const quickUse = (index) => {
        stopStreamFetch();
        const newQuestion = SampleQuery[index];
        setState({...state, question: newQuestion});
    }

    const handleKeyOk = () => {
        localStorage.setItem("apiKey", state.apiKey);
        localStorage.setItem("apiOrg", state.apiOrg);
        // 隐藏模态框
        setState({...state, KeyVisible: false});
    };

    const handleKeyCancel = () => {
        setState({...state, KeyVisible: false});
    };

  return (
    <Modal
      title={SPLName}
      visible={visible}
      onOk={() => { stopStreamFetch(); websocketController.current.close(); onClose(); }} // 传递函数引用
      onCancel={() => { stopStreamFetch(); websocketController.current.close(); onClose(); }} // 传递函数引用
      footer={null}
      width={'100%'}
      height={'100%'}
    >
      <div>
      <Modal
        title="OpenAI API"
        visible={state.KeyVisible}
        onOk={handleKeyOk}
        onCancel={handleKeyCancel}
      >
        <div>
          <label>OpenAI API Key:</label>
          <Input
            value={state.apiKey}
            onChange={(e) => setState({...state, apiKey: e.target.value})}
          />
        </div>
        <div>
          <label>OpenAI API Organization:</label>
          <Input
            value={state.apiOrg}
            onChange={(e) => setState({...state, apiOrg: e.target.value})}
          />
        </div>
      </Modal>
    </div>
      <div id="UGARunBox" style={{height: '60vh', overflowY: 'auto'}}>
        {messages.map((msg, idx) => (
            <div className={`d-flex justify-content-between`} key={msg.time}>
                <pre className={`message ${msg.role}-message`}><Image
                    alt="logo"
                    src= {msg.role === 'user' ? User : SPLSapper}
                    width="35"
                    height="35"
                    className="dialog-portrait-img"
                />{msg.content}</pre>
                {msg.role === "user" ? (
                    <div
                        onClick={() => editMessage(idx)}
                    ><Tooltip title={"new chat"}><i className="fa fa-edit Icon">
                    </i></Tooltip></div>
                ) : (
                    ""
                )}
            </div>
        ))}
        {state.loading ? (
            <p className="loading_wrap">AI is thinking...</p>
        ) : (
            ""
        )}
      </div>
      <div className={`PreviewBox`} style={{display: "flex"}}>
          <Tooltip title={'Set Openai API Key'}><div className="btn StepButton" onClick={() => setState({...state, KeyVisible: true})}>
            <i className={`fa fa-key`} style={{paddingRight: '2px'}}>key</i>
          </div></Tooltip>
          <Tooltip title={"Quick use"}><div className="btn StepButton" style={{marginLeft: '2px'}}><i className={`fa fa-flash`}>
          </i></div></Tooltip>
          {SampleQuery.map((qu, index) => (
            <Tooltip title={qu} color={"#87d068"}>
            <div key={index}
             onClick={()=>quickUse(index)}
             style={{
              color: "#00695c",
              padding: "2px",
              borderRadius: "5px",
              marginLeft: '5px',
              cursor: "pointer",
              border: "1px solid gray",
              transition: "background-color 0.3s",
              textAlign: "left",
              maxWidth: '400px',
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}>
                {qu}
            </div></Tooltip>
          ))}
      </div>
      <div id="input-container" className={`d-flex flex-column justify-content-end`} style={{
            backgroundColor: 'white',
            boxSizing: 'border-box',
            boxShadow: '2px 2px 5px rgba(0,0,0,0.1)',
            borderRight: '2px solid gray',
            borderLeft: '2px solid gray',
            borderRadius: '10px'
        }}>
        <Input.TextArea
            id="inputBox"
            placeholder="Enter your question here and click Enter+Shift to send it"
            value={state.question}
            style={{
                }}
            bordered={false}
            autoSize={{
              maxRows: 10,
              minRows: 2
            }}
            onChange={(e) => setState({...state, question: e.target.value})}
            onKeyDown={(e) => {
                if (e.key === "Enter" && e.shiftKey) {
                  sendMessage(SPLPrompt, messages, state.question);
                }
            }}
        >
        </Input.TextArea>
        <Tooltip title={"Send Message"}><button style={{alignSelf: 'flex-end'}} onClick={() => sendMessage(SPLPrompt, messages, state.question)}><i className="fa fa-send">
        </i></button></Tooltip>
      </div>
    </Modal>
  );
};

export default ChatBox;
