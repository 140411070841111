import React, { useEffect, useState } from 'react';
import {Row, Col, Modal, Button, Input, Checkbox, Card, message, Dropdown, Menu, Tooltip} from 'antd';
import OldChatBox from "./ChatBox";
import './Space.css'

const MarketComponent = ({Author, Password}) => {
    const [aiServices, setAiServices] = useState({});
   useEffect(() => {
      fetch('/sappercommunity/sapperchain/personalAIagent', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          AuthorId: "Market",
          Password: "20011106"
        })
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('服务器返回错误状态码: ' + response.status);
          }
          return response.json();
        })
        .then(data => {
          // 处理响应数据
          setAiServices(data.data);
        })
        .catch(error => {
          // 处理不同类型的错误
          if (error.message.startsWith('服务器返回错误状态码')) {
            console.error('服务器返回错误状态码:', error.message);
          } else {
            console.error('发生其他错误:', error);
          }
      });
  }, []);

  const target2UAGWorkSpace = (WorkId) => {
      const currentHost = window.location.hostname; // 获取当前主机名
      const currentProtocol = window.location.protocol; // 获取当前协议

      // 构建URL
      const baseUrl = `${currentProtocol}//${currentHost}`;
      const workspaceUrl = `${baseUrl}/sappercommunity/workspace?SPLId=${WorkId}`;

      // 使用构建的URL
      window.location.href = workspaceUrl;
  }


  const shareUGA = (WorkId) => {
      // 获取当前项目的可见性
      const currentVisibility = aiServices[WorkId].Visibility;

      if (currentVisibility === 'Private') {
        // 如果可见性是 Private，提醒用户不可分享，除非切换为 Public
        message.error('This project is private. Please switch to public to share it.');
      } else if (currentVisibility === 'Public') {
        // 如果可见性是 Public，生成分享链接
        // 如果可见性是 Public，生成分享链接
        const currentURL = window.location.href;
        // 提取域名
        const domainRegex = /^(https?:\/\/[^/]+)\//;
        const match = currentURL.match(domainRegex);
        const domain = match[1];
        const shareLink = `${domain}/sappercommunity/workspace?SPLId=${WorkId}`;

        // 使用 navigator.clipboard 将分享链接复制到剪贴板
        navigator.clipboard.writeText(shareLink)
          .then(() => {
            message.success('Shareable link has been generated and copied to the clipboard: ' + shareLink);
          })
          .catch((err) => {
            console.error('Failed to copy the shareable link to the clipboard: ', err);
            // 处理复制失败的情况
          });
      }
  };

  const customizationUGA = (WorkId) => {
      // 显示确认模态框
      fetch('/sappercommunity/sapperchain/customizationUGA', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            AuthorId: Author,
            Password: Password,
            WorkId: WorkId
        })
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('服务器返回错误状态码: ' + response.status);
          }
          return response.json();
        })
        .then(data => {
          // 执行其他后续操作
          // ...
            target2UAGWorkSpace(WorkId);
        })
        .catch(error => {
          // 处理服务器请求错误
          console.error('服务器请求错误:', error);
          // 提醒删除失败
          // ...
        });
  };

  const addPersonalUGA = (WorkId) => {
      // 显示确认模态框
      fetch('/sappercommunity/sapperchain/customizationUGA', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            AuthorId: Author,
            Password: Password,
            WorkId: WorkId
        })
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('服务器返回错误状态码: ' + response.status);
          }
          return response.json();
        })
        .then(data => {
          // 执行其他后续操作
          // ...
        })
        .catch(error => {
          // 处理服务器请求错误
          console.error('服务器请求错误:', error);
          // 提醒删除失败
          // ...
        });
  };

  const [isChatOpen, setIsChatOpen] = useState(false); // 控制聊天框显示状态
  const [chatTarget, setChatTarget] = useState({WorkId: '', AuthorId: ''}); // 控制聊天框显示状态


  const handleOpenChat = (WorkId, AuthorId) => {
      setIsChatOpen(true);
      setChatTarget({WorkId: WorkId, AuthorId: AuthorId}); // 设置聊天的目标 WorkId
  };

  const handleCloseChat = () => {
    setIsChatOpen(false);
  };

  return (
  <div style={{ padding: '10px' }}>
    <Row gutter={[16, 16]} style={{paddingBottom: '5px'}}>
      <Col span={12}>
        <Button.Group>
            <Button type="primary">Discover Space</Button>
            <Button onClick={() => {window.location.href = `/sappercommunity`;}}>Personal Space</Button>
        </Button.Group>
      </Col>
    </Row>
    <Row gutter={[16, 16]}>
      {Object.values(aiServices).map((service) => (
        <Col key={service.WorkId} xs={24} sm={12} md={8} lg={6}>
          <Card>
            <Row gutter={[2, 2]} >
              <Col xs={20} sm={20} md={20} lg={20}>
                <p>{service.WorkName}</p>
              </Col>
              <Col xs={2} sm={2} md={2} lg={2}>
                <Tooltip title={"Use"}>
                    <i
                      className={`fa fa-comments-o`}
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleOpenChat(service.WorkId, service.AuthorId)}
                    ></i>
                </Tooltip>
              </Col>
              <Col xs={2} sm={2} md={2} lg={2}>
                <Tooltip title={"Share"}>
                    <i
                      className={`fa fa-share`}
                      style={{ cursor: 'pointer' }}
                      onClick={() => shareUGA(service.WorkId)}
                    ></i>
                </Tooltip>
              </Col>
            </Row>
            <p className="description">{service.Description}</p>
            <Row gutter={[2, 2]} >
                <Col xs={12} sm={12} md={12} lg={12}>
                    <Button onClick={() => addPersonalUGA(service.WorkId)}>
                      Add to Personal
                    </Button>
                </Col>
                {/*<Col xs={12} sm={12} md={12} lg={12}>*/}
                {/*    <Button onClick={() => customizationUGA(service.WorkId)}>*/}
                {/*      Customization*/}
                {/*    </Button>*/}
                {/*</Col>*/}
            </Row>
          </Card>
        </Col>
      ))}
    </Row>
    {isChatOpen && (
      <OldChatBox
        Author={Author}
        Password={Password}
        visible={isChatOpen}
        onClose={handleCloseChat}
        chatTarget={chatTarget} // 传递 chatTarget 给 ChatBox
      />
    )}
  </div>
);
};

export default MarketComponent;
