import React from 'react';
import { Card, Form, Input, Button, message} from 'antd';

const centerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
};

const Login = () => {
  const onFinish = (values) => {
    // 发起登录请求
    fetch('/sappercommunity/sapperchain/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values), // 使用用户填写的表单数据作为请求体
    })
      .then((response) => {
        if (response.status === 200) {
          // 登录成功，可以执行相应的操作
          message.success('Login successful');
          localStorage.setItem('userEmail', values.email);
          localStorage.setItem('userPassword', values.password);
          window.location.href = `/sappercommunity/discover`;
        } else {
          // 登录失败，处理错误
          message.error('Login failure');
        }
      })
      .catch((error) => {
        message.error('An error occurred during login', error);
      });
  };

  return (
    <div style={centerStyle}>
      <Card title="User Login" style={{ width: 300 }}>
        <Form onFinish={onFinish}>
          <Form.Item
            name="email"
            label="name"
            rules={[
              // {
              //   type: 'email',
              //   message: 'Please enter a valid email address',
              // },
              {
                required: true,
                message: 'Please enter your name',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="password"
            label="Password"
            rules={[
              {
                required: true,
                message: 'Please enter your password',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Login
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default Login;
