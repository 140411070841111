import {Container, Image, Nav, Navbar} from "react-bootstrap";
import React from "react";
import {message} from "antd";
const logoImage = process.env.PUBLIC_URL + '/logo.png';


const TopNavBar = () => {
  // 检查用户是否已登录
  const isLoggedIn = !!localStorage.getItem('userEmail');

  const handleLogout = () => {
    // 清除用户信息（在这种情况下，清除用户邮箱）
    localStorage.removeItem('userEmail');
    // 执行注销操作（例如，向服务器发送注销请求）
    message.success("Successful logout");
    window.location.href = `/sappercommunity/login`;
  };

  return (
    <Navbar style={{ height: "60px", backgroundColor: '#e2ebf0', border: '1px solid white' }}>
      <Navbar.Brand href="/sappercommunity/introduce">
        <Image
          alt="logo"
          src={logoImage}
          width="35"
          height="35"
          className="d-inline-block align-top"
        />{' '}
        Prompt Sapper
      </Navbar.Brand>
      <Nav className="ml-auto">
        {isLoggedIn ? (
          // 如果用户已登录，则显示退出登录按钮
          <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
        ) : (
          // 如果用户未登录，则显示注册和登录按钮
          <>
            <Nav.Link href="/sappercommunity/register">Register</Nav.Link>
            <Nav.Link href="/sappercommunity/login">Login</Nav.Link>
          </>
        )}
      </Nav>
    </Navbar>
  );
};


const BottomNavBar = () => {
    return (
        <Navbar fixed="bottom" bg="dark" variant="dark">
            <Container>
                <Navbar.Text>
                    &copy; {new Date().getFullYear()} Your Company Name
                </Navbar.Text>
            </Container>
        </Navbar>
    );
}

export {BottomNavBar, TopNavBar};
