import React ,{useState} from "react";
import "./RunStructuredPrompt.css"
import {Image} from "react-bootstrap";
import {Button, Input, message, Row, Tooltip} from 'antd';
const { TextArea } = Input;

const RunStructuredPrompt = ({system, splJson, query, handleQueryChange, historyRunMessage, handleHistoryChange, handleSavePrompt})=> {
    const User = process.env.PUBLIC_URL + '/user.png';
    const SPLSapper = process.env.PUBLIC_URL + '/splsapper.jpg';
    const initState = {
        endpoint: "https://api.openai.com/v1/chat/completions",
        apiKey: '',
        model: "gpt-3.5-turbo-16k",
        temperature: 0.2,
        max_tokens: 4096,
        stop_sequences: '',
        top_p: 1,
        presence_penalty: 0,
        frequency_penalty: 0,
        overTime: 30000,
        historyMessageNum: undefined,
        historyMessage: historyRunMessage,
        prompts: [{role: "system", content: system}],
        question: "",
        loading: false,
        controller: null,
        runPrompt: true,
        isPromptVisible: false,
        welcomeInfo: ''
    };
    const [state, setState] = useState(initState);
    state.prompts = [{role: "system", content: system}]

    const addMessage = (text, sender)=> {
        let newHistoryMessage = state.historyMessage;
        if (
            sender !== "assistant" ||
            newHistoryMessage[newHistoryMessage.length - 1].role !== "assistant"
        ) {
            newHistoryMessage = [
                ...state.historyMessage.filter(
                    (v) =>
                        ["welcome", "system", "user", "assistant"].includes(v.role) && v.content !== ""
                ),
                {role: sender, content: text, time: Date.now()},
            ];
        } else {
            newHistoryMessage[newHistoryMessage.length - 1].content += text;
            setState({...state, historyMessage: newHistoryMessage})
        }
        state.historyMessage = newHistoryMessage;
        handleHistoryChange(newHistoryMessage);
        setState(state);
        setTimeout(() => {
            scrollToBottom(sender !== "assistant");
        }, 0);
    }

    const editMessage = (idx)=> {
        stopStreamFetch();
        const newQuestion = state.historyMessage[idx].content;
        const newHistoryMessage = state.historyMessage.slice(0, idx);
        handleHistoryChange(newHistoryMessage);
        setState({...state, question: newQuestion, historyMessage: newHistoryMessage});
    }

    const stopStreamFetch = () => {
        if (state.controller) {
            state.controller.abort("__ignore");
        }
    };

    const getResponseFromAPI = async(text)=> {
        const controller = new AbortController();
        state.controller = controller;
        const signal = controller.signal;
        const timeout = setTimeout(() => {
            controller.abort();
        }, state.overTime);
        const messages = [
            ...state.historyMessage
        ]
            .filter(
                (v) => ["system", "user", "assistant"].includes(v.role) && v.content
            )
            .map((v) => ({role: v.role, content: v.content}))
            .slice(-state.historyMessageNum - 1); // 上文消息
        const requestOptions = {
            signal,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "apiKey": localStorage.getItem('apiKey'),
                "apiOrg": localStorage.getItem('apiOrg'),
                "modelConfig": state,
                "messages": state.prompts
                    .concat(
                        messages
                    )
                    .filter((v) => v),
            })
        };
      const response = await fetch('/sappercommunity/sapperchain/getResFromGPT', requestOptions);
        clearTimeout(timeout);
        if (!response.ok) {
            const {error} = await response.json();
            throw new Error(error.message || error.code);
        }
        const reader = response.body.getReader();
        const decoder = new TextDecoder("utf-8");
        const stream = new ReadableStream({
            start(controller) {
                return pump();
                function pump() {
                    return reader.read().then(({done, value}) => {
                        // When no more data needs to be consumed, close the stream
                        if (done) {
                            controller.close();
                            return;
                        }
                        let text = "";
                        const str = decoder.decode(value);
                        const strs = str.split("data: ").filter((v) => v);
                        for (let i = 0; i < strs.length; i++) {
                            const val = strs[i];
                            if (val.includes("[DONE]")) {
                                controller.close();
                                return;
                            }
                            const data = JSON.parse(val);
                            data.choices[0].delta.content &&
                            (text += data.choices[0].delta.content);
                        }
                        controller.enqueue(text);
                        return pump();
                    });
                }
            },
        });
        return new Response(stream);
    }

    const handleSearch = (regenerateFlag)=> {
        const input = state.question;
        if (!regenerateFlag) {
            if (!input) {
                alert("请输入问题");
                return;
            }
            addMessage(input, "user");
            state.question = "";
        }
        setState({...state, loading: true})
        // 使用 OpenAI API 获取 ChatGPT 的回答
        getResponseFromAPI(input)
            .then(async (response) => {
                if (!response.ok) {
                    const error = await response.json();
                    throw new Error(error.error);
                }
                const data = response.body;
                if (!data) throw new Error("No data");

                const reader = data.getReader();
                let done = false;

                while (!done) {
                    const {value, done: readerDone} = await reader.read();
                    if (value) {
                        addMessage(value, "assistant");
                        scrollToBottom();
                    }
                    done = readerDone;
                }
            })
            .catch((error) => {
                if (state.controller.signal.reason === "__ignore") {
                    return;
                }
                console.log('-------------error', state.controller.signal, state.controller.signal.reason, error, error.name, error.message);
                addMessage(
                    error.name === "AbortError" ? "Network Error" : error.message,
                    "warning"
                );
            })
            .finally(() => {
                setState({...state, loading: false})
            });
    }

    const scrollToBottom = (force = true) =>{
        const dom = document.getElementById("RunBox");
        dom.scrollTo({top: dom.scrollHeight, behavior: "smooth"});
    }

    const target2other = () => {
        let params = new URLSearchParams(window.location.search);
        let key = params.get("sapper"); // 这就是从URL中获取的数据
        if(key !== null){
            key = JSON.parse(key)
            const data = {'id': key['posiId'], 'spl': state.prompts[0].content, 'splJson': JSON.stringify(splJson)}
            window.opener.postMessage(JSON.stringify(data), "https://www.promptsapper.tech/sapperenterprise/workspace");
        }
        else {
            handleSavePrompt()
        }
    }

    const handleCopyToClipboard = () => {
        const promptsString = state.prompts[0].content;
        // 使用Clipboard API将内容复制到剪贴板
        navigator.clipboard.writeText(promptsString)
          .then(() => {
            console.log('Prompts copied to clipboard!');
          })
          .catch((err) => {
            console.error('Failed to copy prompts to clipboard: ', err);
          });
    };

    const quickUse = (index) => {
        stopStreamFetch();
        const newQuestion = query[index];
        setState({...state, question: newQuestion});
    }

    return (
      <>
        <div id="PreSetting" style={{ maxWidth: '400px', minWidth: '300px', overflowY: 'auto', backgroundColor: "#ebedee", borderRadius: "10px",border: "1px solid #ccc"}}>
            <div id="ModelBox">
                <div className={`d-flex flex-column`} style={{ paddingBottom: '5px' }}>
                    <div className="" style={{ width: '100px' }}>Model</div>
                    <select
                        className={`ModelInput`}
                        style={{ backgroundColor: '#e3eeff', textAlign: 'left' }}
                        value={state.model}
                        onChange={(e) => {
                            const newState = { ...state };
                            newState.model = e.target.value;
                            setState(newState);
                        }}
                    >
                        <option value="gpt-3.5-turbo-16k">gpt-3.5-turbo-16k</option>
                        <option value="gpt-3.5-turbo-16k">gpt-3.5-turbo</option>
                        <option value="gpt-4">gpt-4</option>
                    </select>
                </div>
                <div className={`d-flex flex-column`} style={{paddingBottom: '5px'}}>
                    <div className="d-flex justify-content-between">
                      <div className="" style={{width: '100px'}}>Temperature</div>
                      <input className={`ModelInput`} style={{width: '100px'}} value={state.temperature}
                        onChange={(e) => {
                            const newState = {...state};
                            newState.temperature = parseFloat(e.target.value);
                            setState(newState);
                        }}
                      />
                    </div>
                    <input type="range" className="form-range" min={0} max={1} step={0.01} value={state.temperature}
                      onChange={(e) => {
                        const newState = {...state};
                        newState.temperature = parseFloat(e.target.value);
                        setState(newState);
                      }}
                    />
                </div>
                <div className={`d-flex flex-column`} style={{paddingBottom: '5px'}}>
                    <div className="d-flex justify-content-between">
                      <div className="" style={{width: '100px'}}>Max length</div>
                      <input className={`ModelInput`} style={{width: '100px'}} value={state.max_tokens}
                        onChange={(e) => {
                            const newState = {...state};
                            newState.max_tokens = parseInt(e.target.value);
                            setState(newState);
                        }}
                      />
                    </div>
                    <input type="range" className="form-range" min={0} max={16000} step={1} value={state.max_tokens}
                      onChange={(e) => {
                        const newState = {...state};
                        newState.max_tokens = parseInt(e.target.value);
                        setState(newState);
                      }}
                    />
                </div>
            </div>
            <div className={`PreviewBox`}>
                <p>Welcome message
                <Tooltip placement="rightTop" title={"Welcome message is the first sentence that the AI says to the user after entering the application.\n" +
                "The content of the welcome message is generally an explanation of the application's functionality."}><i className="fa fa-warning" style={{color: 'yellowgreen', paddingLeft: '2px'}}></i></Tooltip></p>
                <TextArea
                    autoSize={{
                      maxRows: 6,
                      minRows: 2
                    }}
                    onChange={(e) => {
                        let newHistoryMessage = state.historyMessage;
                        newHistoryMessage[0].content = e.target.value;
                        state.historyMessage = newHistoryMessage;
                        handleHistoryChange(newHistoryMessage);
                        setState(state);
                    }}
                    value={state.historyMessage[0].content}
                >
                </TextArea>
            </div>
            <div className={`PreviewBox`}>
                <p>Sample query
                <Tooltip placement="rightTop" title={"You can provide some sample queries to get users started quickly."}><i className="fa fa-warning" style={{color: 'yellowgreen', paddingLeft: '2px'}}></i></Tooltip></p>
                {query.map((qu, index) => (
                <TextArea key={index}
                  autoSize={{
                      maxRows: 2,
                      minRows: 1
                    }}
                    onChange={(e) => {
                        let newquery = query;
                        newquery[index] = e.target.value;
                        query = newquery;
                        handleQueryChange(newquery);
                    }}
                   value={query[index]}
                >
                </TextArea>
              ))}
            </div>
            <div className={`PreviewBox`}>
                <p>External knowledge
                <Tooltip placement="rightTop" title={"Equip your UGA with tailored knowledge by linking web pages or uploading documents, just as you'd coach a new intern or guide a Padawan. "}><i className="fa fa-warning" style={{color: 'yellowgreen', paddingLeft: '2px'}}></i></Tooltip></p>
                <Tooltip title={"In Sapper's Pro and Enterprise versions, this feature is already included. " +
                    "For the Sapper Community version, we are actively working on its development and have plans to add this feature soon. " +
                    "Please stay tuned for our updates to benefit from this feature in future releases. " +
                    "Thank you for your support!"}><Button onClick={() => {message.info("Coming soon")}}><i className={`fa fa-add`}></i>Add Knowledge</Button></Tooltip>
            </div>
        </div>
        <div className={`d-flex flex-grow-1 flex-column w-100`}>
            <div id="PromptBox" style={{minHeight: '60px', maxHeight: '200px'}}>
                <div className={`d-flex justify-content-between`}>
                    <pre className={`message system-message`} style={{lineHeight: '1.1', overflowY: 'hidden'}}>
                        <Image
                            alt="logo"
                            src= {SPLSapper}
                            width="35"
                            height="35"
                            className="dialog-portrait-img"
                        />Prompt:
                    <Tooltip title={"Open/ Close Prompt"}>
                    <i className={`${state.isPromptVisible ? "fa fa-chevron-circle-up Icon" : "fa fa-chevron-circle-down Icon"}`} onClick={()=>{setState({...state, isPromptVisible: !state.isPromptVisible})}}>
                    </i></Tooltip><br/>{state.isPromptVisible ?  state.prompts[0].content : ''}</pre>
                    <div>
                        <Tooltip title={"Get Prompt"}>
                        <i className="fa fa-clone Icon" onClick={handleCopyToClipboard}>
                        </i></Tooltip>
                    </div>
                </div>
            </div>
            <div className={`d-flex flex-grow-1 flex-column`} style={{
              backgroundColor: "lightgray",
              padding: "10px",
              borderRadius: "5px",
              border: "1px solid #ccc",
              overflowY: 'hidden'
            }}>Preview
            <div id="RunBox" className={`d-flex flex-grow-1 flex-column`} style={{overflowY: 'auto'}}>
                {state.historyMessage.map((msg, idx) => (
                    <div className={`d-flex justify-content-between`} key={msg.time}>
                        <pre className={`message ${msg.role}-message`}><Image
                            alt="logo"
                            src= {msg.role === 'user' ? User : SPLSapper}
                            width="35"
                            height="35"
                            className="dialog-portrait-img"
                        />{msg.content}</pre>
                        {msg.role === "user" ? (
                            <div
                                onClick={() => editMessage(idx)}
                            ><Tooltip title={"new chat"}><i className="fa fa-edit Icon">
                            </i></Tooltip></div>
                        ) : (
                            ""
                        )}
                    </div>
                ))}
                {state.loading ? (
                    <p className="loading_wrap">AI is thinking...</p>
                ) : (
                    ""
                )}
            </div>
            <div className={`PreviewBox`} style={{display: "flex", minHeight: '40px'}}>
              <Tooltip title={"Quick use"}><div><i className={`fa fa-flash`}>
              </i></div></Tooltip>
              {query.map((qu, index) => (
                <div key={index}
                 onClick={()=>quickUse(index)}
                 style={{
                  color: "#00695c",
                  padding: "2px",
                  borderRadius: "5px",
                  marginLeft: '5px',
                  cursor: "pointer",
                  border: "1px solid gray",
                  transition: "background-color 0.3s",
                  textAlign: "left",
                  maxWidth: '400px',
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}>
                    {qu}
                </div>
              ))}
            </div>
            {state.runPrompt && <div id="input-container" className={`d-flex flex-column justify-content-end`} style={{
                backgroundColor: 'white',
                boxSizing: 'border-box',
                boxShadow: '2px 2px 5px rgba(0,0,0,0.1)',
                borderRight: '1px solid gray',
                borderLeft: '1px solid gray',
                borderRadius: '10px'
            }}>
            <TextArea
                id="inputBox"
                placeholder="Enter your question here and click Enter+Shift to send it"
                value={state.question}
                style={{

                    }}
                bordered={false}
                autoSize={{
                  maxRows: 10,
                  minRows: 2
                }}
                onChange={(e) => setState({...state, question: e.target.value})}
                onKeyDown={(e) => {
                    if (e.key === "Enter" && e.shiftKey) {
                      handleSearch();
                    }
                }}
            >
            </TextArea>
            <Tooltip title={"Send Message"}><button style={{alignSelf: 'flex-end'}} onClick={() => handleSearch()}><i className="fa fa-send">
            </i></button></Tooltip>
            </div>
            }
            </div>
        </div>
      </>
    );
}
export default RunStructuredPrompt;
