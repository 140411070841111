import React, { useState } from 'react';
import {Card, Form, Input, Button, Row, Col, message} from 'antd';

const centerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
};

const Signup = () => {
  const [form] = Form.useForm();
  const [verificationCodeSent, setVerificationCodeSent] = useState(false);

  const onFinish = (values) => {
    // 获取用户输入的验证码
    const userVerificationCode = values.verificationCode;

    // 发送验证码验证请求
    fetch('/sappercommunity/sapperchain/verifyVerificationCode', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Code: userVerificationCode , 'AuthorEmail': values.email, 'Meta': values}),
    })
      .then((response) => {
        if (response.status === 200) {
          // 验证通过，继续注册逻辑
          // message.success('The verification code succeeds.')
          message.success('Registered successfully')
          // window.location.href = `/sappercommunity/login`;
          // 在需要进行跳转的地方
          setTimeout(() => {
            window.location.href = `/sappercommunity/login`;
          }, 1000); // 延时 1 秒后跳转

          // 此处可以继续处理用户注册逻辑
        }
        else if(response.status === 400){
          response.json().then(data => {
            message.error(data.message); // 获取错误信息
          });
        }
        else {
          // 验证不通过，处理错误
          message.error('The verification code fails');
          // 此处可以处理验证码验证失败的情况
        }
      })
      .catch((error) => {
        message.error('An error occurred during the verification code verification', error);
        // 处理验证码验证过程中的错误
      });
  };


  const validatePassword = (_, value) => {
    // 检查密码是否包含至少六位字符，包括字母和数字
    if (value && value.length >= 6 && /\d/.test(value) && /[a-zA-Z]/.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject('Password must contain at least six characters, including letters and numbers');
  };

  const sendVerificationCode = () => {
    // 获取用户输入的邮箱地址
    const email = form.getFieldValue('email');
    // 检查邮箱格式，可以使用正则表达式或其他方法
    if (isValidEmail(email)) {
      // 发送验证码的逻辑
      fetch('/sappercommunity/sapperchain/sendVerificationCode', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 'AuthorEmail': email}),
      })
        .then((response) => {
          // 处理响应，例如检查是否成功发送验证码
          if (response.status === 200) {
            setVerificationCodeSent(true);
          } else {
            message.error('Failed to send the verification code.');
          }
        })
        .catch((error) => {
          message.error('An error occurred while sending the verification code', error);
        });
    } else {
      // 处理邮箱格式不正确的情况
      message.error('Email address format is incorrect');
    }
  };

  // 验证邮箱格式的简单示例
  const isValidEmail = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return emailRegex.test(email);
  };

  return (
    <div style={centerStyle}>
      <Card title="User Registration" style={{ width: 300 }}>
        <Form form={form} onFinish={onFinish}>
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: 'Please enter your name',
              },
              {
                pattern: /^[\w\u4e00-\u9fa5]{4,}$/, // 正则表达式规则
                message: 'Name must be at least 4 characters long and contain only letters and numbers.',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            initialValue={"UGA@prompt.sapper"}
            style={{ display: 'none' }}
            rules={[
              {
                type: 'email',
                message: 'Please enter a valid email address',
              },
              {
                required: true,
                message: 'Please enter your email',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="password"
            label="Password"
            rules={[
              {
                required: true,
                message: 'Please enter your password',
              },
              {
                validator: validatePassword,
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="verificationCode"
            label="Verification Code"
            initialValue={"test"}
            style={{ display: 'none' }}
            rules={[
              {
                required: true,
                message: 'Please enter the verification code',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Row gutter={8}>
            {/*<Col span={14}>*/}
            {/*  <Form.Item>*/}
            {/*    <Button*/}
            {/*      type="primary"*/}
            {/*      onClick={sendVerificationCode}*/}
            {/*      disabled={verificationCodeSent}*/}
            {/*      block*/}
            {/*    >*/}
            {/*      Verification Code*/}
            {/*    </Button>*/}
            {/*  </Form.Item>*/}
            {/*</Col>*/}
            <Col span={10}>
              <Form.Item>
                <Button type="primary" htmlType="submit" block>
                  Register
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
    </div>
  );
};

export default Signup;
