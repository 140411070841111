import React from "react";
import {TopNavBar} from "./NavBar";
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'
import BuildStructuredPrompt from "./BuildStructuredPrompt";
import SpaceComponent from "./PersonalSpace";
import MarketComponent from "./MarketSpace";
import Signup from "./Register";
import Login from "./Login";

function App() {
  return (
    <Router>
      <div>
          <TopNavBar/>
      </div>
      <Routes>
          <Route path="/sappercommunity/login" element={<Login/>}/>
          <Route path="/sappercommunity/register" element={<Signup/>}/>
          <Route path="/sappercommunity/workspace" element={<BuildStructuredPrompt Author={localStorage.getItem('userEmail')} Password={localStorage.getItem('userPassword')}/>}/>
          <Route path="/sappercommunity" element={<SpaceComponent Author={localStorage.getItem('userEmail')} Password={localStorage.getItem('userPassword')}/>} />
          <Route path="/sappercommunity/discover" element={<MarketComponent Author={localStorage.getItem('userEmail')} Password={localStorage.getItem('userPassword')}/>} />
      </Routes>
    </Router>
  );
}

export default App;
