import React, { useEffect, useState } from 'react';
import './Space.css'
import {Row, Col, Modal, Button, Input, Card, message, Dropdown, Menu, Tooltip} from 'antd';
import OldChatBox from "./ChatBox";
const CreateUGA = ({addUGA, Author, Password}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const initFormData = {
    WorkName: '',
    Description: '',
    Tag: '',
    Visibility: false,
  }
  const [formData, setFormData] = useState(initFormData);

  const showModal = () => {
      if(!Author){
          message.error('Please log in first');
          return;
      }
    setIsModalVisible(true);
  };

  const generateWorkId = () => {
      const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = '';
      const length = 10
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * charset.length);
        result += charset[randomIndex];
      }
      return result;
  }

  const handleOk = () => {
    // 处理表单提交逻辑，可以将 formData 发送到服务器
    if (formData.WorkName.trim() === '') {
        // 如果 WorkName 为空，不执行提交逻辑
        message.error('UGA Name is required.')
        return;
    }
    let WorkId = generateWorkId();
    formData["WorkId"] = WorkId
    formData["Visibility"] = formData["Visibility"] ? "Public" : "Private";
    formData["AuthorId"] = Author;
    const InitUGAgent = {
        "SPLId": WorkId,
        "spl_prompt": "",
        "spl_data": {
          "JsonDate": [],
          "NLDesc": "",
          "SPLName": formData.WorkName,
           "SPLRequirement": formData.Description,
            "WelcomeInfo": "You can ask me anything.",
            "SampleQuery": ["", "", ""]
        }
      };
    fetch('/sappercommunity/sapperchain/createUGAData', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          AuthorId: Author,
          Password: Password,
          WorkId: WorkId,
          UGAgent: InitUGAgent,
          UGAMetaData: formData
        })
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('服务器返回错误状态码: ' + response.status);
          }
          return response.json();
        })
        .then(data => {
          // 处理响应数据
            setFormData(initFormData);
            addUGA(WorkId, formData);
        })
        .catch(error => {
          // 处理不同类型的错误
          if (error.message.startsWith('服务器返回错误状态码')) {
            console.error('服务器返回错误状态码:', error.message);
          } else {
            console.error('发生其他错误:', error);
          }
        }).finally(setIsModalVisible(false))
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;
    const updatedValue = type === 'checkbox' ? e.target.checked : value;
    setFormData((prevData) => ({
      ...prevData,
      [name]: updatedValue,
    }));
  };

  const importUGA = () => {
      if(!Author){
          message.error('Please log in first');
          return;
      }
  // Create an input element to select a file
  const inputElement = document.createElement('input');
  inputElement.type = 'file';

  // Add an event listener to execute an action after selecting a file
  inputElement.addEventListener('change', (event) => {
        const file = event.target.files[0];

        // Create a FileReader to read the file content
        const reader = new FileReader();

        reader.onload = (e) => {
          try {
            // Try to parse the file content as JSON
            const WorkId = generateWorkId();
            const filedata = JSON.parse(e.target.result);

            // Send the parsed data to the server
            fetch('/sappercommunity/sapperchain/createUGAData', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                AuthorId: Author,
                Password: Password,
                WorkId: WorkId,
                UGAgent: filedata.ugadata,
                UGAMetaData: filedata.metadata
              })
            })
            .then(response => {
              if (!response.ok) {
                throw new Error('Server returned an error status code: ' + response.status);
              }
              return response.json();
            })
            .then(data => {
              // Handle the response data
              setFormData(initFormData);
              addUGA(WorkId, filedata.metadata);
            })
            .catch(error => {
              // Handle different types of errors
              if (error.message.startsWith('Server returned an error status code')) {
                console.error('Server returned an error status code:', error.message);
              } else {
                console.error('Other error occurred:', error);
              }
            })
            .finally(() => {
              setIsModalVisible(false);
            });
          } catch (error) {
            console.error('Unable to parse JSON file', error);
          }
        };
        reader.readAsText(file);
      });

      // Simulate a user click on the input element to select a file
      inputElement.click();
  }

  return (
    <div>
      <Button.Group>
          <Button type="primary" onClick={showModal}>
            Create UGA
          </Button>
          <Button type="primary" onClick={importUGA}>
            Import UGA
          </Button>
      </Button.Group>
      <Modal title="Create UGA" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <div>
          <label>UGA Name:</label>
          <Input
            type="text"
            name="WorkName"
            value={formData.WorkName}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label>Description:</label>
          <Input.TextArea
            type="text"
            name="Description"
            value={formData.Description}
            onChange={handleInputChange}
            showCount
            maxLength={300}
              style={{
                height: 80,
                resize: 'none',
              }}
          />
        </div>
        <div>
          <label>Tag:</label>
          <Input
            type="text"
            name="Tag"
            value={formData.Tag}
            onChange={handleInputChange}
          />
        </div>
        {/*<div>*/}
        {/*  <label>Public:</label>*/}
        {/*  <Checkbox*/}
        {/*    name="Visibility"*/}
        {/*    checked={formData.Visibility}*/}
        {/*    onChange={handleInputChange}*/}
        {/*  />*/}
        {/*</div>*/}
      </Modal>
    </div>
  );
};

const SpaceComponent = ({Author, Password}) => {
    const [aiServices, setAiServices] = useState({});
   useEffect(() => {
      if(Author){
          fetch('/sappercommunity/sapperchain/personalAIagent', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              AuthorId: Author,
              Password: Password
            })
          })
            .then(response => {
              if (!response.ok) {
                throw new Error('服务器返回错误状态码: ' + response.status);
              }
              return response.json();
            })
            .then(data => {
              // 处理响应数据
                console.log(data.data)
              setAiServices(data.data);
            })
            .catch(error => {
              // 处理不同类型的错误
              if (error.message.startsWith('服务器返回错误状态码')) {
                console.error('服务器返回错误状态码:', error.message);
              } else {
                console.error('发生其他错误:', error);
              }
          });
      }else {
          message.error("Please log in first.")
      }
  }, [Author, Password]);

  const shiftVisibility = (WorkId) => {
      // 获取当前项目的可见性
      const currentVisibility = aiServices[WorkId].Visibility;
      // 计算要切换到的可见性
      const newVisibilityText = currentVisibility === 'Public' ? 'Unpublish' : 'Publish';
      const newVisibility = currentVisibility === 'Public' ? 'Private' : 'Public';

      // 显示确认模态框
      Modal.confirm({
        title: 'Confirm Publish to Market Space',
        content: `Are you sure you want to switch this item to ${newVisibilityText} ?`,
        onOk() {
          // 发送请求切换可见性
          const newAiServices = { ...aiServices};
            // 更新项目的可见性
          newAiServices[WorkId].Visibility = newVisibility;
          fetch('/sappercommunity/sapperchain/ChangeMetaData', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              AuthorId: Author,
              Password: Password,
              WorkId: WorkId,
              UGAgent: newAiServices
            })
          })
            .then(response => {
              if (!response.ok) {
                throw new Error('Server returned an error status code: ' + response.status);
              }
              return response.json();
            })
            .then(data => {
              // 服务器数据修改成功后执行状态更新和后续操作
              setAiServices(newAiServices);
              // 执行其他后续操作
              // ...
            })
            .catch(error => {
              // 处理服务器请求错误
              message.error('Server request error:', error);
              // 提醒更改Visibility失败
              // ...
            });
        },
        onCancel() {
          // 用户点击取消后执行的操作
        },
      });
  };

  const target2UAGWorkSpace = (WorkId) => {
       const currentURL = window.location.href;

        // 提取域名
        const domainRegex = /^(https?:\/\/[^/]+)\//;
        const match = currentURL.match(domainRegex);

        if (match) {
          const domain = match[1];
          window.location.href = `/sappercommunity/workspace?SPLId=${WorkId}`;
        } else {
          console.log("未找到域名");
        }
  }

  const deleteUGA = (WorkId) => {
      // 显示确认模态框
      Modal.confirm({
        title: 'Confirm deletion',
        content: 'Are you sure you want to delete this UGA?',
        onOk() {
          // 发送请求删除 UGA
          fetch('/sappercommunity/sapperchain/deleteUGAData', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                AuthorId: Author,
                Password: Password,
                WorkId: WorkId
            })
          })
            .then(response => {
              if (!response.ok) {
                throw new Error('服务器返回错误状态码: ' + response.status);
              }
              return response.json();
            })
            .then(data => {
              // 服务器数据删除成功后执行状态更新和后续操作
              setAiServices((prevAiServices) => {
                // 创建新的 aiServices 对象，不要直接修改之前的状态对象
                const newAiServices = { ...prevAiServices };
                // 删除指定的 AI 服务项目
                delete newAiServices[WorkId];
                return newAiServices; // 返回新的状态对象
              });
              // 执行其他后续操作
              // ...
            })
            .catch(error => {
              // 处理服务器请求错误
              console.error('服务器请求错误:', error);
              // 提醒删除失败
              // ...
            });
        },
        onCancel() {
          // 用户点击取消后执行的操作
        },
      });
  };

  const addUGA = (WorkId, WorkData) =>{
      setAiServices((prevAiServices) => {
        // 创建新的 aiServices对象，不要直接修改之前的状态对象
        const newAiServices = { ...prevAiServices };
        newAiServices[WorkId] = WorkData
        return newAiServices; // 返回新的状态对象
      });

      setTimeout(() => {
        window.location.href = `/sappercommunity/workspace?SPLId=${WorkId}`;
      }, 1000); // 延时 1 秒后跳转
  }

  const shareUGA = (WorkId) => {
      // 获取当前项目的可见性
      const currentVisibility = aiServices[WorkId].Visibility;

      if (currentVisibility === 'Private') {
        // 如果可见性是 Private，提醒用户不可分享，除非切换为 Public
        message.error('This project is private. Please switch to public to share it.');
      } else if (currentVisibility === 'Public') {
        // 如果可见性是 Public，生成分享链接
        const currentURL = window.location.href;
        // 提取域名
        const domainRegex = /^(https?:\/\/[^/]+)\//;
        const match = currentURL.match(domainRegex);
        const domain = match[1];
        const shareLink = `${domain}/sappercommunity/workspace?SPLId=${WorkId}`;
        // 使用 navigator.clipboard 将分享链接复制到剪贴板
        navigator.clipboard.writeText(shareLink)
          .then(() => {
            message.success('Shareable link has been generated and copied to the clipboard: ' + shareLink);
          })
          .catch((err) => {
            console.error('Failed to copy the shareable link to the clipboard: ', err);
            // 处理复制失败的情况
          });
      }
  };

  const downloadUGA = (WorkId, WorkName) => {
      fetch('/sappercommunity/sapperchain/downloadUGAData', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          AuthorId: Author,
          Password: Password,
          WorkId: WorkId
        })
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Server returned an error status code: ' + response.status);
          }
          return response.blob();
        })
        .then(data => {

          const blobUrl = window.URL.createObjectURL(data);
          const downloadLink = document.createElement('a');
          downloadLink.href = blobUrl;
          downloadLink.download = `${WorkName}.json`;
          downloadLink.click();

          // Display a success message to the user
          alert('Download successful');
        })
        .catch(error => {
          if (error.message.startsWith('Server returned an error status code')) {
            alert('Server returned an error status code: ' + error.message);
          } else {
            alert('An error occurred: ' + error);
          }
        });
  };

  const [isChatOpen, setIsChatOpen] = useState(false); // 控制聊天框显示状态
  const [chatTarget, setChatTarget] = useState({WorkId: '', AuthorId: ''}); // 控制聊天框显示状态


  const handleOpenChat = (WorkId, AuthorId) => {
      setIsChatOpen(true);
      setChatTarget({WorkId: WorkId, AuthorId: AuthorId}); // 设置聊天的目标 WorkId
  };

  const handleCloseChat = () => {
    setIsChatOpen(false);
  };

  return (
  <div style={{ padding: '10px' }}>
    <Row gutter={[16, 16]} style={{paddingBottom: '5px'}}>
      <Col span={12}>
        <Button.Group>
            <Button onClick={() => {window.location.href = `/sappercommunity/discover`;}}>Discover Space</Button>
            <Button type="primary">Personal Space</Button>
        </Button.Group>
      </Col>
      <Col span={12} style={{ textAlign: 'right' }}>
        <CreateUGA addUGA={addUGA} Author={Author} Password={Password}/>
      </Col>
    </Row>
    <Row gutter={[16, 16]}>
      {Object.values(aiServices).map((service) => (
        <Col key={service.WorkId} xs={24} sm={12} md={8} lg={6}>
          <Card>
            <Row gutter={[4, 4]} >
              <Col xs={16} sm={16} md={16} lg={16}>
                <p>{service.WorkName}</p>
              </Col>
              <Col xs={2} sm={2} md={2} lg={2}>
                <Tooltip title={"Use"}>
                    <i
                      className={`fa fa-comments-o`}
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleOpenChat(service.WorkId, service.AuthorId)}
                    ></i>
                </Tooltip>
              </Col>
              <Col xs={2} sm={2} md={2} lg={2}>
                 <Tooltip title={"Publish"}>
                    <i
                      className={`${
                        service.Visibility === 'Public'
                          ? 'fa fa-unlock'
                          : 'fa fa-lock'
                      }`}
                      style={{ cursor: 'pointer' }}
                      onClick={() => shiftVisibility(service.WorkId)}
                    ></i>
                 </Tooltip>
              </Col>
              <Col xs={2} sm={2} md={2} lg={2}>
                <Tooltip title={"Edit"}>
                    <i
                      className={`fa fa-edit`}
                      style={{ cursor: 'pointer' }}
                      onClick={() => target2UAGWorkSpace(service.WorkId)}
                    ></i>
                </Tooltip>
              </Col>
              <Col xs={2} sm={2} md={2} lg={2}>
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item key="share" onClick={() => shareUGA(service.WorkId)}><i className={`fa fa-share`}></i>Share</Menu.Item>
                        <Menu.Item key="download" onClick={() => downloadUGA(service.WorkId, service.WorkName)}><i className={`fa fa-download`}></i>Download</Menu.Item>
                        <Menu.Item key="delete" onClick={() => deleteUGA(service.WorkId)}><i className={`fa fa-trash`}></i>Delete</Menu.Item>
                      </Menu>
                    }
                    placement="bottomRight"
                  >
                    <i className={`fa fa-ellipsis-v`} style={{ cursor: 'pointer' }}></i>
                  </Dropdown>
              </Col>
            </Row>
            <p className="description">{service.Description}</p>
          </Card>
        </Col>
      ))}
    </Row>
    {isChatOpen && (
      <OldChatBox
        Author={Author}
        Password={Password}
        visible={isChatOpen}
        onClose={handleCloseChat}
        chatTarget={chatTarget} // 传递 chatTarget 给 ChatBox
      />
    )}
  </div>
);
};

export default SpaceComponent;
